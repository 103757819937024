<template>
  <div>
    <v-bottom-sheet
      v-model="edit_user"
      inset
      max-width="600px"
    >
      <v-sheet
        class="rounded-t-xl"
      >
        <v-toolbar
          dense
          flat
          color="transparent"
        >
          <v-btn
            icon
            @click="edit_user = false"
          >
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-spacer />
          <v-toolbar-title>{{ $t('bianji_ziliao') }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>

        <v-sheet
          class="pa-3"
        >
          <v-text-field
            v-model="newusername"
            filled
            rounded
            background-color="app_bg"
            :label="$t('username')"
            value="zhangsan"
            hide-details="auto"
            class="mb-3"
          />
          <v-text-field
            v-model="newpassword"
            filled
            rounded
            background-color="app_bg"
            :label="$t('password')"
            hide-details="auto"
            append-icon="pw_show ? 'mdi-eye' : 'mdi-eye-off'"
            type="pw_show ? 'text' : 'password'"
            class="mb-3"
            @click:append="pw_show = !pw_show"
          />
          <v-textarea
            v-model="newremark"
            filled
            rounded
            background-color="app_bg"
            :label="$t('beizhu')"
            hide-details="auto"
          />

          <v-btn
            block
            x-large
            rounded
            color="primary"
            class="mt-5"
            @click="edituser"
          >
            {{ $t('tijiao') }}
          </v-btn>
        </v-sheet>
      </v-sheet>
    </v-bottom-sheet>

    <v-dialog
      v-model="edit_done"
      max-width="300"
    >
      <div class="text-right">
        <v-btn
          fab
          small
          dark
          fixed
          class="mt-n5 ml-n5 opacity-7"
          @click="closecopy"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </div>

      <v-card class="rounded-xl">
        <div class="text-center py-6">
          <v-avatar
            color="primary"
            size="64"
            class="rounded-xl"
          >
            <v-icon
              dark
              large
            >
              mdi-check-bold
            </v-icon>
          </v-avatar>
          <div class="text-h5 text-center primary--text pt-3">
            {{ $t('bianji_chenggong') }}
          </div>
        </div>
        <v-divider class="opacity-3" />

        <v-card-text ref="editdone">
          <v-list-item>
            <v-list-item-content class="">
              {{ $t('username') }}
            </v-list-item-content>
            <v-list-item-action>
              {{ newusername }}
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="">
              {{ $t('password') }}
            </v-list-item-content>
            <v-list-item-action>
              {{ newpassword }}
            </v-list-item-action>
          </v-list-item>

          <v-btn
            block
            outlined
            large
            rounded
            color="primary"
            dark
            @click="doCopy(newusername+'\n'+newpassword)"
          >
            {{ $t('copy_one') }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'Editinfo',
  props: {
    role: {
      type: String,
      required: true
    },
    userinfo: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    edit_user: true,
    pw_show: false,
    edit_done: false,
    newusername: '',
    newpassword: '',
    newremark: ''
  }),
  computed: {

  },
  watch:{
    edit_user: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal)
        if(newVal==false){
          this.$emit('close_editinfo')
        }
      }
    },
  },
  created() {
    
  },
  mounted() {
    this.newusername = this.userinfo.username
    this.newremark = this.userinfo.remark
  },
  methods: {
    edituser() {
      let paramObj = {
        agent_id: this.getStorage('agent_id'),
        role: this.role,
        id: this.userinfo.id,
        username: this.newusername,
        password: this.newpassword,
        remark: this.newremark,
      }
      this.$server.edituser(paramObj).then((response) => {
        if(response.code==200){
          this.edit_done = true
        }else{
          this.$snackbar.warning(response.msg)
        }
      })
    },
    closecopy() {
      this.edit_user = false
    },
    doCopy: function (text) {
      text = ''+text//转string
      this.$copyText(text, this.$refs.editdone).then( (e)=>{
        console.log(e)
        this.$snackbar.info(this.$t('copy_ok'))
      }, function (e) {
        console.log(e)
      })
    },
  },
};
</script>